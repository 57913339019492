<!-- 扫码订单管理 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入订单号"
              class="search-input"
              prefix-icon="search"
              size="small"
              clearable
              @input="searchInput"
              v-model="indentIdValue"
            >
            </el-input>
            <el-input
              placeholder="请输入设备号"
              class="search-input"
              prefix-icon="search"
              size="small"
              clearable
              @input="searchInput"
              v-model="deviceMacValue"
            >
            </el-input>
            <!-- <el-input
              placeholder="请输入用户ID"
              class="search-input"
              prefix-icon="search"
              size="small"
              clearable
              @input="searchInput"
              v-model="userIdValue"
            >
            </el-input> -->
            <el-input
              placeholder="请输入手机号"
              class="search-input"
              prefix-icon="search"
              size="small"
              clearable
              @input="searchInput"
              v-model="phoneValue"
            >
            </el-input>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择订单状态"
              size="small"
              v-model="statusValue"
              @change="handleStateChange"
            >
              <el-option
                v-for="item in deviceAgencyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
          </el-col>
        </el-row>
        <el-row style="width: 100%; margin: 0 0 15px 0" v-if="userLevel === 0">
          <el-date-picker
            v-model="formatValue"
            type="datetimerange"
            size="small"
            :picker-options="datetimerange"
            value-format="YYYYMMDD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
          </el-date-picker>
          <el-button type="primary" style="margin-left: 4px" size="small" @click="downloadTemplate"
            >导出Excel</el-button>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
            show-overflow-tooltip
          >
            <template #default="{ row }" v-if="item.prop === 'headUrl'">
              <img
                class="imgUrl"
                :src="row.headUrl"
                @click="onPicture(row.headUrl)"
              />
            </template>
            <template #default="{ row }" v-if="item.prop === 'status'">
              <span :class="sizeClass(row)">{{ statusSize(row) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template #default="{ row }">
              <el-button type="text" @click="editClick(row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--订单详情-->
      <el-dialog
        v-model="dialogVisible"
        width="40%"
        :title="dialogTitleName"
        :close-on-click-modal="false"
        @closed="resetForm"
      >
        <el-form
          label-width="135px"
          :inline="true"
          :model="detailsFormData"
          :rules="Rules"
          ref="editRuleFormRef"
        >
          <!--          <el-row :gutter="20">-->
          <!--            <el-col :span="22">-->
          <!--              <el-form-item style="text-align: center">-->
          <!--                <img class="headImage" :src="detailsFormData.headUrl" />-->
          <!--              </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="用户ID：">
                <span>{{ detailsFormData.userId }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号：">
                <span>{{ detailsFormData.phone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="订单号：">
                <span>{{ detailsFormData.indentId }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备号：">
                <span>{{ detailsFormData.deviceMac }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="总用电量(/度)：">
                <span>{{ detailsFormData.electricity }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起始功率(/瓦)：">
                <span>{{ detailsFormData.initialPower }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="订单总价(/元)：">
                <span>{{ detailsFormData.orderPrice }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付金额(/元)：">
                <span>{{ detailsFormData.practical }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="订单总时长(/分钟)：">
                <span>{{ detailsFormData.workTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际消费金额(/元)：">
                <span>{{ detailsFormData.endPrice }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="充电端口数：">
                <span>{{ detailsFormData.portNumber }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="充电站名称：">
                <span>{{ detailsFormData.stationName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          <el-row :gutter="20">-->
          <!--            <el-col :span="22">-->
          <!--              <el-form-item label="一级代理利润："> </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
          <!--          <el-row :gutter="20">-->
          <!--            <el-col :span="22">-->
          <!--              <el-form-item label="二级代理利润："> </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
          <!--          <el-row :gutter="20">-->
          <!--            <el-col :span="22">-->
          <!--              <el-form-item label="平台利润：" prop="deviceVersion">-->
          <!--              </el-form-item>-->
          <!--            </el-col>-->
          <!--          </el-row>-->
        </el-form>
        <!-- 表格区域 -->
        <div class="page-container">
          <el-table
            ref="profitTableRef"
            :data="profitTableData"
            style="width: 100%"
            max-height="230px"
            :header-cell-style="{ background: '#F2F4F8' }"
          >
            <el-table-column
              v-for="(item, index) in profitTableColumnData"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetEditForm">取消 </el-button>
            <el-button
              type="primary"
              @click="submitEditForm('allocateDeviceRuleFormRef')"
              >保存
            </el-button>
          </span>
        </template> -->
      </el-dialog>
    </el-card>
    <!-- 图片放大预览 -->
    <el-dialog width="30%" title="图片预览" v-model="imgVisible">
      <div style="height: 400px; text-align: center">
        <img style="width: 400px; height: 400px" :src="imgUrl" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import {
  selectPagesApi,
  delMerchantApi,
  indentXlsApi,
} from "@/api/indentManagement/scanCodeList/scanCodeList.js";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
export default {
  name: "AccountSearch",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      indentIdValue: null, //搜索订单号
      deviceMacValue: null, //搜索设备号
      userIdValue: null, // 搜索用户id
      phoneValue: null, // 搜索手机号
      statusValue: null, // 搜索状态
      formatValue: '',
      tableData: [], // 表格数据
      imgVisible: false, // 图片预览弹框
      imgUrl: null, //
      total: 0,
      pageSize: 10, // 一页显示十条
      currentPage: 1, //默认第一页
      deviceMac: "",
      phone: "",
      status: "",
      indentId: "",
      userId: "",
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      formData: {
        alarTime: [], //起始时间
      },
      tableColumnData: [
        {
          prop: "indentId",
          label: "订单号",
        },
        // {
        //   prop: "userId",
        //   label: "用户ID",
        // },
        // {
        //   prop: "phone",
        //   label: "手机号",
        // },
        {
          prop: "deviceMac",
          label: "设备号",
        },
        // {
        //   prop: "headUrl",
        //   label: "微信头像",
        // },
        // {
        //   prop: "electricity",
        //   label: "总用电量",
        // },
        // {
        //   prop: "initialPower",
        //   label: "起始功率",
        // },
        {
          prop: "orderPrice",
          label: "订单总价",
        },
        {
          prop: "practical",
          label: "支付金额",
          width: "200",
        },
        {
          prop: "endPrice",
          label: "实际消费金额",
          width: "200"
        },
        // {
        //   prop: "workTime",
        //   label: "订单总时长",
        // },
        // {
        //   prop: "portNumber",
        //   label: "端口号",
        // },
        {
          prop: "chargeType",
          label: "收费类型",
        },
        // {
        //   prop: "divideMoney",
        //   label: "分成金额",
        // },
        // {
        //   prop: "rate",
        //   label: "分成比例",
        // },
        {
          prop: "status",
          label: "状态",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      deviceAgencyList: [
        {
          value: "",
          label: "全部状态",
        },
        {
          value: 1,
          label: "待支付",
        },
        {
          value: 2,
          label: "进行中",
        },
        {
          value: 3,
          label: "超时未付款",
        },
        {
          value: 4,
          label: "取消订单",
        },
        {
          value: 5,
          label: "手动停止",
        },
        {
          value: 6,
          label: "充电完成",
        },
      ],
      // 订单详情对话框
      dialogVisible: false,
      dialogTitleName: "订单详情",
      // 订单详情form
      detailsFormData: {
        headUrl: null, // 微信头像
        userId: null, //用户id
        phone: null, // 手机号
        indentId: null, // 订单号
        deviceMac: null, // 设备号
        electricity: null, // 总用电量
        initialPower: null, // 起始功率
        orderPrice: null, // 订单总价
        practical: null, // 支付金额
        endPrice: null,   // 实际消费金额
        portNumber: null, // 端口号
        chargeType: null, // 收费类型
        orgId: null, // 充电站地址（主键）
        stationName: null, // 充电站名称
        workTime: null,
      },
      editDeviceRules: {},
      profitTableData: [], //详情中利润表格
      profitTableColumnData: [
        {
          prop: "merchantName",
          label: "商户名称",
        },
        {
          prop: "money",
          label: "分成金额",
        },
        {
          prop: "rate",
          label: "分成比例",
        },
      ],
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      methods.selectPages();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.selectPages();
    }, 500);
    const methods = {
      // 点击编辑
      editClick(row) {
        data.dialogVisible = true;
        data.detailsFormData = {
          headUrl: row.headUrl, // 微信头像
          userId: row.userId, //用户id
          phone: row.phone, // 手机号
          indentId: row.indentId, // 订单号
          deviceMac: row.deviceMac, // 设备号
          electricity: (row.electricity / 1000), // 总用电量
          initialPower: row.initialPower, // 起始功率
          orderPrice: row.orderPrice, // 订单总价
          practical: row.practical, // 支付金额
          endPrice: row.endPrice,   // 实际消费金额
          portNumber: row.portNumber, // 端口号
          chargeType: row.chargeType, // 收费类型
          stationName: row.stationName, // 充电站名称
          workTime: row.workTime,
        };
        data.profitTableData = row.rateMoneyDtos;
      },
      // 编辑确认
      submitEditForm() {
        data.dialogVisible = false;
      },
      // 取消
      resetEditForm() {
        data.dialogVisible = false;
      },
      //下拉框查询
      handleStateChange() {
        methods.selectPages();
      },
      // 搜索按钮
      searchClick() {
        methods.selectPages();
      },
      // 查询扫码订单分页
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              type: 0, //0 扫码订单 1 刷卡订单
              current: data.currentPage,
              size: data.pageSize,
              indentId: data.indentIdValue,
              deviceMac: data.deviceMacValue,
              // userId: data.userIdValue,
              phone: data.phoneValue,
              status: data.statusValue,
              merchantId: data.userInfo.merchantId.toString(),
            };
            return selectPagesApi(params);
          },
          (res) => {
            if (res.code === 200) {
              // proxy.tableData = res.data.records.map((it) => {
              //   return {
              //     indentId: it.indentId, // 订单号
              //     indentId: it.indentId, // 订单主键
              //     userId: it.userId, //用户id
              //     phone: it.phone, // 手机号
              //     deviceMac: it.deviceMac, // 设备号
              //     headUrl: it.headUrl, // 微信头像
              //     electricity: it.electricity, // 总用电量
              //     initialPower: it.initialPower, // 起始功率
              //     orderPrice: it.orderPrice, // 订单总价
              //     practical: it.practical, // 支付金额
              //     portNumber: it.portNumber, // 端口号
              //     chargeType: methods.charTYpe(it.chargeType), // 收费类型
              //     orgId: it.orgId, // 充电站地址（主键）
              //     stationName: it.stationName, // 充电站名称
              //     status: it.status, // 状态
              //     updateUser: it.updateUser,
              //     workTime: it.workTime, // 订单总时长
              //     createTime: it.createTime
              //   };
              // });
              proxy.tableData = res.data.records;
              if (proxy.tableData.length > 0) {
                for (let i = 0; i < proxy.tableData.length; i++) {
                  proxy.tableData[i].chargeType = methods.charTYpe(
                    proxy.tableData[i].chargeType
                  );
                  proxy.tableData[i].orderPrice = (
                    proxy.tableData[i].orderPrice / 100
                  ).toFixed(2);
                  proxy.tableData[i].practical = (
                    proxy.tableData[i].practical / 100
                  ).toFixed(2);
                  proxy.tableData[i].endPrice = (
                    proxy.tableData[i].endPrice / 100
                  ).toFixed(2)
                }
              }
              proxy.total = res.data.records ? parseInt(res.data.total) : 0;
            }
          }
        );
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPages();
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPages();
      },
      // 删除订单
      async confirmEvent(id) {
        const params = {
          Ids: id.indentId,
        };
        await requestApi(
          () => {
            return delMerchantApi(params);
          },
          (res) => {
            if (res.code === 200) {
              methods.selectPages();
            }
          }
        );
      },
      // 点击图片放大
      onPicture(row) {
        console.log(row.headUrl);
        proxy.imgUrl = row;
        data.imgVisible = true;
      },
      charTYpe(val) {
        console.log(val, 111);
        if (val == 1) {
          return "电量收费";
        } else {
          return "计时收费";
        }
      },
      // 状态显示
      statusSize(fol) {
        if (fol.status == 1) {
          return "待支付";
        } else if (fol.status == 2) {
          return "进行中";
        } else if (fol.status == 3) {
          return "超时未付款";
        } else if (fol.status == 4) {
          return "取消订单";
        } else if (fol.status == 5) {
          return "手动停止";
        } else {
          return "充电完成";
        }
      },
      // 状态显示颜色
      sizeClass(fol1) {
        if (fol1.status == 1) {
          return "fontcolor";
        } else if (fol1.status == 2) {
          return "fontcolor1";
        } else if (fol1.status == 3) {
          return "fontcolor2";
        } else if (fol1.status == 4) {
          return "fontcolor2";
        } else {
          return "fontcolor";
        }
      },
      insertStr(source, start, newStr) {
        return source.slice(0, start) + newStr + source.slice(start)
      },
      // 导出订单
      downloadTemplate() {
        // return console.log(proxy.formatValue);
        if(proxy.formatValue == '' || proxy.formatValue == null) {
          return proxy.$msgbox
          .confirm("请选择时间导出对应数据", "提示", {
            closeOnClickModal: false,
            confirmButtonText: "确定",
            showCancelButton: false,
            type: "warning",
          })
        }
        proxy.$msgbox
        .confirm("确认导出" +  methods.insertStr(methods.insertStr(proxy.formatValue[0], 4, '-'), 7, '-') + '到' + methods.insertStr(methods.insertStr(proxy.formatValue[1], 4, '-'), 7, '-') + '的所有数据?', "导出", {
            closeOnClickModal: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            methods.deriveXlsAll();
          })
          .catch(() => {
            proxy.$refs.tableRef.clearSelection();
          });
      },
      // 导出订单接口
      deriveXlsAll() {
        // const ids = proxy.selection + "";
        const params = {
          deviceMac: '',
          merchantId: data.userInfo.merchantId,
          type: 0,
          acTime: proxy.formatValue[0],
          endTime: proxy.formatValue[1]
        };
        requestApi(
          () => {
            return indentXlsApi(params);
          },
          (res) => {
            methods.downloadFile(res, "扫码订单列表");
          }
        );
      },
      // 处理下载请求
      downloadFile(res,name) {
        const url = URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");
        document.body.appendChild(a); // 此处增加了将创建的添加到body当中
        a.href = url;
        a.download = name + new Date().getTime() + ".xls";
        a.target = "_blank";
        a.click();
        a.remove(); // 将a标签移除
        proxy.$refs.tableRef.clearSelection();
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.fontcolor {
  color: #00cd00;
}
.fontcolor1 {
  color: #00ee00;
}
.fontcolor2 {
  color: #ee4000;
}
.fontcolor3 {
  color: #ee0000;
}
.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
.imgUrl {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.headImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
</style>
