import { axios } from '@/utils/request'

const api = {
    selectPages: 'charging/indent/pagesIndent', // 查询扫码订单
    delMerchant: 'charging/indent/delIndent',    // 删除订单
    indentXls: 'charging/indent/IndentXls',      // 导出订单
}
export default api

// 查询设备
export const selectPagesApi = (params) => {
    return axios({
        url: api.selectPages,
        method: 'post',
        params
    })
}

// 删除订单
export const delMerchantApi = (params) => {
    return axios({
        url: api.delMerchant,
        method: 'post',
        params
    })
}

// 导出订单
export const indentXlsApi = (params) => {
    return axios({
        url: api.indentXls,
        responseType: 'blob',
        method: 'get',
        params
    })
}